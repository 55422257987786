import InfoBox from "components/common/boxes/InfoBox/InfoBox";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { changeAcademyVisibility } from "services/academyServices";
import {
  ACADEMY_ACCESS_STATUS,
  ACADEMY_TYPE,
  ACADEMY_VISIBILITY,
  IAcademy,
  IAcademyMembersCount,
} from "types/academy.interface";
import logo from "assets/images/logo.svg";
import transferService from "services/transfer/transferService";
import { enumToSelectOptions } from "utils/baseUtils";
import { showServerErrors } from "utils/errorsUtils";
import { updateAcademyAccessState } from "services/academyServices";
import { useParams } from "react-router-dom";

interface IAcademyInfoProps {
  academy: IAcademy;
  refresh: () => Promise<void>;
}

const accessStates = enumToSelectOptions(ACADEMY_ACCESS_STATUS);

const AcademyInfo = ({ academy, refresh }: IAcademyInfoProps) => {
  const [accessState, setAccessState] = useState<ACADEMY_ACCESS_STATUS>(
    academy.AccessStatus,
  );
  const [isAccessChangeing, setAccessChangeing] = useState(false);
  const [visibility, setVisibility] = useState(academy.Visibility);
  const [membersCount, setMembersCount] =
    useState<IAcademyMembersCount | null>(null);
  const { id } = useParams<{ id: string }>();
  const isAcademyPrivate =
    academy.Visibility === ACADEMY_VISIBILITY["Prywatny"];

  const academyImage = !!academy.PhotoFilePath ? academy.PhotoFilePath : logo;

  const handleToggleRecruitment = () => {
    setAccessState((prevState) => {
      switch (prevState) {
        case ACADEMY_ACCESS_STATUS["Nabór zamknięty"]:
          return ACADEMY_ACCESS_STATUS["Nabór otwarty"];
        case ACADEMY_ACCESS_STATUS["Nabór otwarty"]:
          return ACADEMY_ACCESS_STATUS["Nabór zamknięty"];
        case ACADEMY_ACCESS_STATUS["Obserwacja zawodników"]:
          return ACADEMY_ACCESS_STATUS["Dołączenie do akademii"];
        case ACADEMY_ACCESS_STATUS["Dołączenie do akademii"]:
          return ACADEMY_ACCESS_STATUS["Obserwacja zawodników"];
        default:
          return prevState;
      }
    });
  };

  const handleToggleObservation = () => {
    setAccessState((prevState) => {
      switch (prevState) {
        case ACADEMY_ACCESS_STATUS["Nabór zamknięty"]:
          return ACADEMY_ACCESS_STATUS["Obserwacja zawodników"];
        case ACADEMY_ACCESS_STATUS["Obserwacja zawodników"]:
          return ACADEMY_ACCESS_STATUS["Nabór zamknięty"];
        case ACADEMY_ACCESS_STATUS["Nabór otwarty"]:
          return ACADEMY_ACCESS_STATUS["Dołączenie do akademii"];
        case ACADEMY_ACCESS_STATUS["Dołączenie do akademii"]:
          return ACADEMY_ACCESS_STATUS["Nabór otwarty"];
        default:
          return prevState;
      }
    });
  };

  const getAcademyMembersCount = async () => {
    try {
      const response = await transferService.getAcademyMembersCount(id);

      setMembersCount(response);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const changeAccessState = async () => {
    try {
      await updateAcademyAccessState(id, accessState);

      await refresh();

      setAccessChangeing(false);
    } catch (error: any) {
      showServerErrors(error);
      setAccessState(academy.AccessStatus);
      setAccessChangeing(false);
    }
  };

  const changeVisibility = async (checked: boolean) => {
    try {
      const changedVisibility = checked
        ? ACADEMY_VISIBILITY["Publiczny"]
        : ACADEMY_VISIBILITY["Prywatny"];

      await changeAcademyVisibility(id, changedVisibility);

      setVisibility(changedVisibility);

      await refresh();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  useEffect(() => {
    getAcademyMembersCount();
  }, [academy]);

  useEffect(() => {
    if (academy.AccessStatus !== accessState) {
      setAccessChangeing(true);
      changeAccessState();
    }
  }, [accessState]);
  console.log("ACADEAMEEME>>", academy);
  return (
    <div className="flex flex-col">
      <InfoBox className="p-18">
        <InfoBox.Image src={academyImage} />
        <InfoBox.Items>
          <InfoBox.InfoItem label={"Pełna nazwa"} value={`${academy.Name}`} />

          <InfoBox.InfoItem
            label="Zawodników"
            value={membersCount !== null ? `${membersCount.PlayersCount}` : "-"}
          />
          <InfoBox.InfoBoxItem label={"Widoczność"}>
            {ACADEMY_VISIBILITY[visibility]}
            <Switch
              checked={visibility === ACADEMY_VISIBILITY["Publiczny"]}
              onChange={(checked) => changeVisibility(checked)}
              disabled={!academy}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </InfoBox.InfoBoxItem>

          <InfoBox.InfoItem label={"Typ"} value={ACADEMY_TYPE[academy.Type]} />
          <InfoBox.InfoItem
            label="Trenerów"
            value={
              membersCount !== null ? `${membersCount.TrainersCount}` : "-"
            }
          />
          <InfoBox.InfoBoxItem label={"Nabór"}>
            <Switch
              checked={accessState === 3 || accessState === 2}
              onChange={handleToggleRecruitment}
              disabled={isAccessChangeing || isAcademyPrivate}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </InfoBox.InfoBoxItem>
          {/* <InfoBox.SelectiveItem
            label={"Nabór"}
            value={
              !isAcademyPrivate
                ? accessState
                : ACADEMY_ACCESS_STATUS["Nabór zamknięty"]
            }
            values={accessStates}
            setValue={setAccessState}
            disabled={isAccessChangeing || isAcademyPrivate}
          /> */}

          <InfoBox.InfoItem label={"NIP"} value={academy.NIP} />
          <InfoBox.InfoItem />
          <InfoBox.InfoBoxItem label={"Obserwacja"}>
            <Switch
              checked={accessState === 3 || accessState === 1}
              onChange={handleToggleObservation}
              disabled={isAccessChangeing || isAcademyPrivate}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </InfoBox.InfoBoxItem>
        </InfoBox.Items>
      </InfoBox>
    </div>
  );
};

export default AcademyInfo;
