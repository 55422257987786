import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router";
import HomeSearchIco from "../../assets/icons/home-search-ico.png";
import styled from "styled-components";
import { useRef, useEffect } from "react";

const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
`;

interface IUsersTopbarProps {
  handleQueryChange: (value: string) => void;
  sortBy: any;
  setSortBy: any;
  sorts: any;
  defaultSort: any;
  viewType: any;
  setViewType: any;
  queryString: any;
  searchFolders: any;
  getSearchFoldersPathFn: any;
  isSearchOpen: any;
  setIsSearchOpen: any;
  setIsSearching: any;
}

const StructureTopbar: React.FC<IUsersTopbarProps> = ({
  handleQueryChange,
  sortBy,
  setSortBy,
  sorts,
  defaultSort,
  setViewType,
  viewType,
  queryString,
  searchFolders,
  getSearchFoldersPathFn,
  isSearchOpen,
  setIsSearchOpen,
  setIsSearching,
}) => {
  const { push } = useHistory();
  const searchBoxRef: any = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSearchOpen]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };
  return (
    <div
      className="w-full"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        padding: "2px 10px",
      }}
    >
      <SearchBox ref={searchBoxRef}>
        <SearchInput
          className="w-full md:w-36 xl:w-72"
          placeholder="Szukaj..."
          autoComplete="off"
          name="search"
          onChange={handleSearch}
          onFocus={() => setIsSearching(true)}

          // onBlur={() => setIsSearchOpen(false)}
        />
        <div>
          <img src={HomeSearchIco} alt="ico" />
        </div>
        {isSearchOpen && (
          <>
            {searchFolders.length > 0 ? (
              <div>
                {searchFolders.map((folder: any) => {
                  const { FolderId, FolderName, Name, Type } = folder;
                  return (
                    <div
                      onClick={() => getSearchFoldersPathFn(FolderId)}
                      key={FolderId}
                    >
                      <p>{Name}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <div>
                  <p>Brak wyników</p>
                </div>
              </div>
            )}
          </>
        )}
      </SearchBox>
    </div>
  );
};

export default StructureTopbar;

const IcoBox = styled.div`
  width: 48px;
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgb(163, 158, 157);
  cursor: pointer;
`;

const SearchBox = styled.div`
  /* margin-top: 4px; */
  height: 44px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 10px;

  & > input {
    width: calc(100% - 45px);
  }

  & > div:first-of-type {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }

  & > div:nth-of-type(2) {
    position: absolute;
    top: 48px;
    left: 0;
    width: 280px;
    max-height: 1000px;
    background: rgba(10, 10, 10, 0.8);
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > div {
      width: 100%;
      height: 60px;
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 0 20px;
      background: rgba(255, 255, 255, 0.01);
      transition: all 0.05s ease-in-out;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
        transition: all 0.05s ease-in-out;
      }

      & > p {
        color: white;
        font-size: 13px;
      }
    }
  }
`;
