import HeaderContainer from "components/common/Containers/HeaderContainer";
import { InfoContainer, StyledTableInput } from "styles/styles";
import InfoContainerItem from "components/common/Containers/InfoContainerItem";
import { usePrimaryDataFormContext } from "../usePrimaryDataFormContext";

const AcademyGeneralAddress = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  return (
    <HeaderContainer title="Adres">
      <InfoContainer className="w-full">
        <InfoContainerItem header="Kraj" content={form?.address?.Country} />
        <InfoContainerItem
          header="Kod"
          content={
            !editEnable ? (
              form.primaryData.AddressPostCode
            ) : (
              <StyledTableInput
                type="text"
                // @ts-ignore
                value={form.primaryData.AddressPostCode}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressPostCode: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Województwo"
          content={
            !editEnable ? (
              form.primaryData.AddressRegion
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.AddressRegion}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressRegion: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Miejscowość"
          content={
            !editEnable ? (
              form.primaryData.AddressCity
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.AddressCity}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressCity: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Ulica"
          content={
            !editEnable ? (
              form.primaryData.AddressStreet
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.AddressStreet}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressStreet: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Budynek"
          content={
            !editEnable ? (
              form.primaryData.AddressBuildingNumber
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.AddressBuildingNumber}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressBuildingNumber: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Numer"
          content={
            !editEnable ? (
              form.primaryData.AddressFlatNumber
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.AddressFlatNumber}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      AddressFlatNumber: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyGeneralAddress;
