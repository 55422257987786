import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { validationMessage } from "utils/validation";
import { showServerErrors } from "utils/errorsUtils";
import eventsServices from "services/eventsServices";
import systemServices from "services/systemServices";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import SubmitButton from "components/common/buttons/submitButton/SubmitButton";
import TextField from "components/common/inputs/textInput/TextField";
// @ts-ignore
import Modal from "react-modal";
import TextInput from "components/common/inputs/textInput/TextInput";
import NewImageField from "components/common/inputs/imageInput/NewImageField";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import TextAreaField from "components/common/inputs/textArea/TextAreaField";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import BlockedDateTextInput from "components/common/inputs/textInput/BlockedDateTextInput";
import BackButton from "components/common/buttons/basicButton/BackButton";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { createAcademyBranch } from "services/academyServices";

interface ICreateUnionProps {
  toggleMenuOpen?: any;
}

const AddAcademyUnion: React.FC<ICreateUnionProps> = ({ toggleMenuOpen }) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const [isUnionChecked, setUnionChecked] = useState(false);
  const [entryDate, setEntryDate] = useState("");
  const [base64, setBase64] = useState("");

  const [visibility, setVisibility] =
    useState<{ value: number; label: string } | null>(null);
  const [visibilities, setVisibilities] = useState<
    { value: number; label: string }[]
  >([]);

  const [accessStatus, setAccessStatus] =
    useState<{ value: number; label: string } | null>(null);
  const [accessStatuses, setAccessStatuses] = useState<
    { value: number; label: string }[]
  >([]);

  const unionValidation = Yup.object().shape({
    Name: Yup.string().required(validationMessage.isRequired),
    ShortName: Yup.string().required(validationMessage.isRequired),
    WebsiteUrl: Yup.string().required(validationMessage.isRequired),
  });

  const getAllVisibilities = async () => {
    try {
      setVisibilities([
        {
          label: "Prywatna",
          value: 0,
        },
        {
          label: "Publiczna",
          value: 1,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVisibilities();
  }, []);

  const handleUnionCheckboxChange = () => {
    setUnionChecked((prev: any) => !prev);
  };

  const token = localStorage.getItem("token");
  const decodedToken: any = token && jwtDecode(token);

  const unionInitialValues = {
    AcademyId: decodedToken?.AcademyId,
    Name: "",
    ShortName: "",
    LogoFilePath: "",
    Description: "",
    WebsiteUrl: "",
    AccessStatus: null,
    Visibility: null,
    IsInUnion: false,
    Union: {
      Name: "",
      Number: "",
      EntryDate: "",
    },
  };

  const handleSubmit = async (values: any) => {
    try {
      console.log("Academybranchvalues", values);

      if (!base64) return toast.error("Dodaj logo!");

      const logoUrl = await systemServices.uploadFile(values?.LogoFilePath);
      await createAcademyBranch(
        values.AcademyId,
        values.Name,
        values.ShortName,
        logoUrl,
        values.WebsiteUrl,
      );

      toast.success("Dodano oddział akademii!");
      goBack();

      window.location.reload();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <Formik
      initialValues={unionInitialValues}
      onSubmit={handleSubmit}
      validationSchema={unionValidation}
    >
      {({ errors, isSubmitting, values, initialValues, setFieldValue }) => (
        <Form
          className="flex"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Container
            style={{
              display: "flex",
              width: "100%",
              height: "calc(100% - 70px)",
              overflowY: "auto",
            }}
          >
            <NameBox>
              <BackButton
                className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
                variant={ButtonVariant.Submit}
                style={{ width: "48px", height: "48px" }}
                onClick={() => toggleMenuOpen(false)}
              >
                <i>{<Arrow className="fill-current w-7" />}</i>
              </BackButton>

              <TextField
                name="Name"
                label={"Nazwa"}
                isError={false}
                style={{
                  paddingBottom: "9px",
                  width: "calc(100% - 48px - 35px)",
                  textAlign: "left",
                }}
              />
            </NameBox>

            <ColumnsContainer>
              <SingleColumn
                style={{ alignItems: "center", paddingLeft: "15px" }}
              >
                <p>DANE PODSTAWOWE</p>

                <NewImageField
                  name="LogoFilePath"
                  titleName="Logo"
                  className="mx-auto md:mx-0 mb-8"
                  imgSrc={values.LogoFilePath}
                  base64={base64}
                  setBase64={setBase64}
                />
                <TextField name="ShortName" label={"Nazwa skrócona"} />

                <TextField name="WebsiteUrl" label={"www"} />
              </SingleColumn>
            </ColumnsContainer>
          </Container>
          <div
            style={{
              display: "flex",
              marginLeft: "15px",
              height: "65px",
              minHeight: "65px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",

                height: "100%",
                borderTop: "5px solid rgba(163,158,157,0.3)",
                width: "calc(100% - 59px)",
              }}
            >
              <SubmitButton
                isSubmitting={isSubmitting}
                variant={ButtonVariant.Save}
                style={{
                  width: "280px",
                  marginTop: "13px",
                }}
                submitText="Tworzenie oddziału akademii..."
              >
                Utwórz oddział akademii
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAcademyUnion;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
const NameBox = styled.div`
  padding: 0 15px;
  display: flex;
  gap: 20px;
`;

const SingleColumn = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;

  & > p {
  }

  & > * {
    width: 280px;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const CheckBoxDiv = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  align-items: center;

  & > div:nth-of-type(1) {
    width: calc(100% - 46px);
    text-align: right;

    & > p {
      color: white;
      font-size: 15px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > input {
      width: 28px;
      height: 28px;
    }
  }
`;
