import HeaderContainer from "components/common/Containers/HeaderContainer";
import { InfoContainer, StyledTableInput } from "styles/styles";
import InfoContainerItem from "components/common/Containers/InfoContainerItem";
import { usePrimaryDataFormContext } from "../usePrimaryDataFormContext";

const AcademyGeneralArea = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  return (
    <HeaderContainer title="Obszar">
      <InfoContainer className="w-full">
        <InfoContainerItem
          header="Widoczność"
          content={
            form.VisibilityRadiusInKilometers.Kilometers !== 0 &&
            form.VisibilityRadiusInKilometers?.Kilometers !== null
              ? "Region"
              : "Kraj"
          }
        />
        <InfoContainerItem
          header="Promień"
          content={
            !editEnable ? (
              form.VisibilityRadiusInKilometers?.Kilometers !== 0 &&
              form.VisibilityRadiusInKilometers?.Kilometers !== null ? (
                `${form?.VisibilityRadiusInKilometers?.Kilometers} km`
              ) : (
                "-"
              )
            ) : (
              <StyledTableInput
                type="text"
                value={form.VisibilityRadiusInKilometers.Kilometers}
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    VisibilityRadiusInKilometers: {
                      Kilometers: +e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyGeneralArea;
