import TabContent from "components/common/tabs/TabContent";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import AcademyUnion from "./DataFields/AcademyUnion";
import HeaderContainer from "components/common/Containers/HeaderContainer";
import AcademyAdministratorContact from "./DataFields/AcademyAdministratorContact";
import AcademyDescription from "./DataFields/AcademyDescription";
import {
  getAcademyPrimaryData,
  getAcademyPrimaryDataForm,
} from "middleware/academy/getAcademyPrimaryData";
import { useAcademyTabsContext } from "components/academy/hook/useAcademyTabsContext";
import AcademyGeneralData from "./DataFields/AcademyGeneralData";
import { toast } from "react-toastify";
import {
  updateAcademyPrimaryData,
  updateAcademyUnion,
  updateAcademyDescription,
  updateAcademyVisibilityRadius,
} from "services/academyServices";
import { showServerErrors } from "utils/errorsUtils";
import { IUpdateAcademyUnion } from "types/academy.interface";
import InfoHeaderContent from "components/common/FormTable/InfoHeaderContent";
import { IAcademyPrimaryDataForm } from "./primaryData.interface";
import AcademyPhoto from "./DataFields/AcademyPhoto";
import AcademyGeneralAddress from "./DataFields/AcademyGeneralAddress";
import AcademyGeneralArea from "./DataFields/AcademyGeneralArea";
import { useParams } from "react-router-dom";

interface IPrimaryDataFormContext {
  form: IAcademyPrimaryDataForm;
  setForm: Dispatch<SetStateAction<IAcademyPrimaryDataForm>>;
  editEnable: boolean;
}

export const PrimaryDataFormContext = createContext<IPrimaryDataFormContext>(
  null!,
);

interface Props {
  isUpdatePrimaryData?: boolean | undefined;
}

const AcademyInformations: React.FC<Props> = ({ isUpdatePrimaryData }) => {
  const { data: academy, refresh } = useAcademyTabsContext();
  const { id } = useParams<{ id: string }>();
  const primaryData = getAcademyPrimaryDataForm(academy);

  const [primaryDataForm, setPrimaryDataForm] =
    useState<IAcademyPrimaryDataForm>(primaryData);

  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSave = async () => {
    setLoading(true);

    try {
      if (!primaryDataForm.primaryData.Description) {
        setLoading(false);
        return toast.error("Opis jest wymagany");
      }

      if (!primaryDataForm.primaryData.NameShort) {
        setLoading(false);
        return toast.error("Skrócona nazwa jest wymagana");
      }

      if (!primaryDataForm.primaryData.WebsiteURL) {
        setLoading(false);
        return toast.error("Strona www jest wymagana");
      }

      if (primaryDataForm.union.IsInUnion && !primaryDataForm.union.Name) {
        setLoading(false);
        return toast.error("Nazwa związku jest wymagana");
      }

      if (primaryDataForm.union.IsInUnion && !primaryDataForm.union.Number) {
        setLoading(false);
        return toast.error("Numer związku jest wymagany");
      }

      const { Description, ...rest } = primaryDataForm.primaryData;

      if (primaryDataForm.primaryData.Description) {
        await updateAcademyDescription(id, Description);
      }

      await updateAcademyPrimaryData(id, primaryDataForm.primaryData);

      if (primaryDataForm.union.IsInUnion) {
        await updateAcademyUnion(primaryDataForm.union as IUpdateAcademyUnion);
      }

      await updateAcademyVisibilityRadius(
        id,
        primaryDataForm.VisibilityRadiusInKilometers.Kilometers,
      );

      await refresh();

      setEditing(false);
    } catch (error: any) {
      showServerErrors(error);
      setPrimaryDataForm(primaryData);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (prev: boolean) => {
    if (prev) {
      setPrimaryDataForm(primaryData);
    }
    return !prev;
  };

  return (
    <TabContent id="informations">
      <div className="flex flex-col w-full gap-12">
        <HeaderContainer
          title="Informacje Podstawowe"
          className="h-16 border-solid border-l-4 border-orange"
          headerContent={
            <div
              onClick={() => {
                if (!isUpdatePrimaryData) {
                  toast.error(
                    "Nie masz uprawnień. Skontaktuj się z Administratorem",
                  );
                }
              }}
            >
              <div
                style={{
                  pointerEvents: isUpdatePrimaryData ? "auto" : "none",
                  opacity: !isUpdatePrimaryData ? "0.6" : "",
                }}
              >
                <InfoHeaderContent
                  handleEdit={() => setEditing(handleEdit)}
                  isEditing={isEditing}
                  isLoading={isLoading}
                  onSave={onSave}
                />
              </div>
            </div>
          }
        >
          <PrimaryDataFormContext.Provider
            value={{
              editEnable: isEditing,
              form: primaryDataForm,
              setForm: setPrimaryDataForm,
            }}
          >
            <div className="grid w-full grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 pt-12">
              <div className="flex flex-col gap-4">
                <AcademyPhoto />
                <AcademyGeneralData />
                <AcademyGeneralArea />
              </div>
              <div className="flex flex-col gap-4">
                <AcademyGeneralAddress />
                <AcademyUnion />
              </div>
              <AcademyDescription />
            </div>
          </PrimaryDataFormContext.Provider>
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default AcademyInformations;
