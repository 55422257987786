import { IAcademyPrimaryDataForm } from "components/academy/detail/tabs/Informations/primaryData.interface";
import {
  IAcademy,
  IUpdateAcademyPrimaryData,
  IUpdateAcademyUnion,
} from "types/academy.interface";

export const getAcademyPrimaryData = (
  academy: IAcademy
): IUpdateAcademyPrimaryData => ({
  Description: academy.Description,
  NameShort: academy.NameShort,
  PhotoFilePath: academy.PhotoFilePath,
  WebsiteURL: academy.WebsiteURL,
  Email: academy?.Contact?.Email,
  PhoneNumber: academy?.Contact?.PhoneNumber,
  AddressRegion: academy?.Address?.Region,
  AddressCity: academy?.Address?.City,
  AddressStreet: academy?.Address?.Street,
  AddressBuildingNumber: academy?.Address?.BuildingNumber,
  AddressFlatNumber: academy?.Address?.FlatNumber,
  AddressPostCode: academy?.Address?.PostCode,
});

export const getAcademyPrimaryDataForm = (
  academy: IAcademy
): IAcademyPrimaryDataForm => ({
  primaryData: {
    Description: academy.Description,
    NameShort: academy.NameShort,
    PhotoFilePath: academy.PhotoFilePath,
    WebsiteURL: academy.WebsiteURL,
    Email: academy?.Contact?.Email,
    PhoneNumber: academy?.Contact?.PhoneNumber,
    AddressRegion: academy?.Address?.Region,
    AddressCity: academy?.Address?.City,
    AddressStreet: academy?.Address?.Street,
    AddressBuildingNumber: academy?.Address?.BuildingNumber,
    AddressFlatNumber: academy?.Address?.FlatNumber,
    AddressPostCode: academy?.Address?.PostCode,
  },
  address: {
    BuildingNumber: academy?.CorrespondenceAddress?.BuildingNumber,
    City: academy?.CorrespondenceAddress?.City,
    Country: "Polska",
    FlatNumber: academy?.CorrespondenceAddress?.FlatNumber,
    PostCode: academy?.CorrespondenceAddress?.PostCode,
    Region: academy?.CorrespondenceAddress?.Region,
    Street: academy?.CorrespondenceAddress?.Street,
  },
  union: academy.Union,
  IsVisibilityRadiusSet: academy.IsVisibilityRadiusSet,
  VisibilityRadiusInKilometers: {
    Kilometers: academy.VisibilityRadiusInKilometers.Kilometers,
  },
});
