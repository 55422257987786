import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GridColumn } from "styles/styles";
import AcademyDocumentsAddForm from "./AcademyDocumentsAddForm";
import AcademyDocumentsRow from "./AcademyDocumentsRow";
import { IPass, IPassDetail } from "types/transfer/pass.interface";
import AcademyDocumentsHeaders from "./AcademyDocumentsHeaders";

interface IAcademyPassesTableProps {
  isAddFormDisplayed: boolean;
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  documents: any[];
  setDocument: Dispatch<SetStateAction<any | null>>;
  refreshDocuments: () => Promise<void>;
}

const AcademyDocumentsTable = ({
  displayAddForm,
  isAddFormDisplayed,
  isEditMode,
  documents,
  setDocument,
  refreshDocuments,
}: IAcademyPassesTableProps) => {
  const [updatedDocument, setUpdatedDocument] = useState<any>();

  useEffect(() => {
    console.log(updatedDocument);
  }, [updatedDocument]);

  return (
    <div>
      <GridColumn cols={6} largerColumn={1}>
        <AcademyDocumentsHeaders />
        {isAddFormDisplayed && (
          <AcademyDocumentsAddForm
            displayAddForm={displayAddForm}
            refreshDocuments={refreshDocuments}
          />
        )}

        {documents.map((document: any, idx: number) => (
          <AcademyDocumentsRow
            isEditMode={isEditMode}
            updatedDocument={
              updatedDocument?.PolicyDocumentId === document.PolicyDocumentId
                ? updatedDocument
                : undefined
            }
            setUpdatedDocument={setUpdatedDocument}
            document={document}
            setDocument={setDocument}
            refreshDocuments={refreshDocuments}
            key={idx}
          />
        ))}
      </GridColumn>
    </div>
  );
};

export default AcademyDocumentsTable;
