import DataTable, {
  IDataTableRows,
} from "components/common/dataTable/DataTable";
import {
  IProductDto,
  PRODUCT_ACTIVATION_STATUS,
  PRODUCT_TYPES,
  PRODUCT_VERIFICATION_STATUS,
} from "types/marketpleace/product.interface";
import { sortProductsByListSort } from "middleware/product/sortProductsByListSort";
import { ITableProps, LIST_SORT } from "types/globalTypes";

const AcademiesTable = ({
  isDataLoading,
  containerRef,
  lastItemRef,
  sortBy,
  data,
}: ITableProps<IProductDto, LIST_SORT>) => {
  const sortedProducts = sortProductsByListSort(data, sortBy?.value);

  const visibilitySwitch = (visibility: number) => {
    switch (visibility) {
      case 0:
        return "Prywatna";
      case 1:
        return "Publiczna";

      default:
        return visibility;
    }
  };

  const rows = sortedProducts.map(
    (academy: any) =>
      ({
        data: { link: `academy/detail/${academy.AcademyId}` },
        cols: [
          academy.Name,
          `${academy?.Address?.City}, ${academy?.Address?.Street}`,
          `${visibilitySwitch(academy?.Visibility)}`,
          academy?.AccessStatus === 3 || academy?.AccessStatus === 2
            ? "TAK"
            : "NIE",
          academy?.AccessStatus === 3 || academy?.AccessStatus === 1
            ? "TAK"
            : "NIE",
        ],
      } as IDataTableRows),
  );
  return (
    <DataTable
      rows={rows}
      headers={[
        "Nazwa skrócona",
        "Lokalizacja",
        "Widoczność",
        "Nabór",
        "Obserwacja",
      ]}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default AcademiesTable;
