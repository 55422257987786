import { conn } from "../api/BaseConnection";
import { ISeller, ISellerDetail } from "types/seller.interface";

const endpoint = conn.endpoints.seller;

const getSeller = async (id: string): Promise<ISellerDetail> => {
  const response = await conn.getJSON<ISeller>(endpoint.get, "json", {
    AcademyId: id,
  });
  return response.Seller;
};

const getBasicSeller = async () => {
  const response = await conn.getJSON(endpoint.getSellerBasicInfo, "json");
  return response;
};

const sellerServices = {
  getSeller,
  getBasicSeller,
};

export default sellerServices;
