import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DigitalReleaseForm from "./DigitalReleaseForm";
import { showServerErrors } from "utils/errorsUtils";
import eventsServices from "services/eventsServices";
import systemServices from "services/systemServices";
import cyclicalEventServices from "services/cyclicalEventServices";

interface Props {
  selectedAcademyId?: string;
  isAddMenuOpen?: any;
  isStructure?: boolean;
  clickedFolderPlayers?: any;
  selectedFolderId?: any;
  toggleAddMenuOpen?: any;
}

const AddDigitalRelease: React.FC<Props> = ({
  selectedAcademyId,
  isAddMenuOpen,
  isStructure,
  clickedFolderPlayers,
  selectedFolderId,
  toggleAddMenuOpen,
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const newProduct: any = {
    Name: "",
    Country: 177,
  };

  const handleCyclicalSubmit = async (
    digitalReleaseData: any,
    AcademyId: any,
    OccurenceDate: any,
    EndDate: any,
    Visibility: any,
    ActivityLevel: any,
    LocalizationType: any,
    ActivityType: any,
    Limit: any,
    allPlayers: any,
    OrganizerType: any,
    RepetitationInterval: any,
    Latitude: number,
    Longitude: number,
    IsCalendarChecked: boolean,
    TimeInterval: any,
    IsPostChecked: boolean,
    IsDraft: boolean,
    FolderIds: any,
    PostCode: any,
    Localization: any
  ) => {
    try {
      const newProduct = digitalReleaseData;
      let structurePlayers;

      const newOccurenceDate = new Date(OccurenceDate);
      const occurenceDateUTC = newOccurenceDate.toISOString();

      const newEndDate = new Date(EndDate);
      const endDateUTC = newEndDate.toISOString();

      if (isStructure) {
        structurePlayers = clickedFolderPlayers.map((player: any) => ({
          Id: player.PlayerId,
          Type: 1,
        }));
      }

      const imageUrl =
        IsPostChecked && newProduct?.photos
          ? await systemServices.uploadFile(newProduct?.photos)
          : "";

      if (isStructure) {
        await cyclicalEventServices.addCyclicalEvent(
          newProduct,
          selectedAcademyId,
          imageUrl,
          occurenceDateUTC,
          endDateUTC,
          2,
          ActivityLevel,
          LocalizationType,
          ActivityType,
          false,
          structurePlayers,
          OrganizerType,
          RepetitationInterval,
          Latitude,
          Longitude,
          IsCalendarChecked,
          TimeInterval,
          IsPostChecked,
          IsDraft,
          // @ts-ignore
          selectedFolderId ? [selectedFolderId] : [],
          PostCode,
          Localization
        );
      } else {
        await cyclicalEventServices.addCyclicalEvent(
          newProduct,
          AcademyId,
          imageUrl,
          occurenceDateUTC,
          endDateUTC,
          Visibility,
          ActivityLevel,
          LocalizationType,
          ActivityType,
          Limit,
          allPlayers,
          OrganizerType,
          RepetitationInterval,
          Latitude,
          Longitude,
          IsCalendarChecked,
          TimeInterval,
          IsPostChecked,
          IsDraft,
          FolderIds,
          PostCode,
          Localization
        );
      }
      if (IsDraft) {
        toast.success("Dodano draft zajęcia!");
      } else {
        toast.success("Dodano cykliczne zajęcie!");
      }
      // goBack();
      if (!isStructure) {
        window.location.reload();
      }
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };
  const handleSubmit = async (
    digitalReleaseData: any,
    AcademyId: any,
    OccurenceDate: any,
    EndDate: any,
    Visibility: any,
    ActivityLevel: any,
    LocalizationType: any,
    ActivityType: any,
    Limit: any,
    allPlayers: any,
    IsCalendarChecked: boolean,
    TimeInterval: any,
    IsPostChecked: boolean,
    Latitude: number,
    Longitude: number,
    FolderIds: any,
    PostCode: any,
    Localization: any
  ) => {
    try {
      const newProduct = digitalReleaseData;
      let structurePlayers;

      const newOccurenceDate = new Date(OccurenceDate);
      const occurenceDateUTC = newOccurenceDate.toISOString();

      const newEndDate = new Date(EndDate);
      const endDateUTC = newEndDate.toISOString();

      if (isStructure) {
        structurePlayers = clickedFolderPlayers.map((player: any) => ({
          Id: player.PlayerId,
          Type: 1,
        }));
      }
      const imageUrl = IsPostChecked
        ? await systemServices.uploadFile(newProduct?.photos)
        : "";

      if (isStructure) {
        await eventsServices.addEvent(
          newProduct,
          selectedAcademyId,
          imageUrl,
          occurenceDateUTC,
          endDateUTC,
          2,
          ActivityLevel,
          LocalizationType,
          ActivityType,
          false,
          structurePlayers,
          IsCalendarChecked,
          TimeInterval,
          IsPostChecked,
          Latitude,
          Longitude,
          selectedFolderId ? [selectedFolderId] : [],
          PostCode,
          Localization
        );
      } else {
        await eventsServices.addEvent(
          newProduct,
          AcademyId,
          imageUrl,
          occurenceDateUTC,
          endDateUTC,
          Visibility,
          ActivityLevel,
          LocalizationType,
          ActivityType,
          Limit,
          allPlayers,
          IsCalendarChecked,
          TimeInterval,
          IsPostChecked,
          Latitude,
          Longitude,
          FolderIds,
          PostCode,
          Localization
        );
      }

      toast.success("Dodano zajęcie!");
      goBack();
      if (!isStructure) {
        window.location.reload();
      }
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <>
      <DigitalReleaseForm
        selectedAcademyId={selectedAcademyId}
        product={newProduct}
        clickedFolderPlayers={clickedFolderPlayers}
        isStructure={isStructure}
        // @ts-ignore
        onSubmit={handleSubmit}
        // @ts-ignore
        onCyclicalSubmit={handleCyclicalSubmit}
        isEdit={false}
        isAddMenuOpen={isAddMenuOpen}
        toggleAddMenuOpen={toggleAddMenuOpen}
      />
    </>
  );
};

export default AddDigitalRelease;
