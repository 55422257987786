import { conn } from "api/BaseConnection";
import api from "./axiosConfig";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const getAuthData = () => {
  const token = localStorage.getItem("token");
  if (!token) return { token: null, AcademyId: null };

  const decodedToken: any = jwtDecode(token);
  return { token, AcademyId: decodedToken?.AcademyId };
};

export const getRootFolder = async (AcademyId: string) => {
  try {
    const response = await api.get(`/api/Folder/GetRootFolders`, {
      params: {
        AcademyId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAvailablePlayerFolders = async (AcademyId: string) => {
  try {
    const response = await api.get(`/api/Folder/GetAvailablePlayerFolders`, {
      params: {
        AcademyId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFolderDetail = async (AcademyId: string, FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/GetFolderDetail?FolderId=${FolderId}`,
      {
        params: {
          AcademyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPlayerPasses = async (AcademyId: string, FolderId: string) => {
  try {
    const response = await api.get(
      `/api/Folder/GetPlayerPasses?FolderId=${FolderId}`,
      {
        params: {
          AcademyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFolderItemsByFolderId = async (
  AcademyId: string,
  FolderId: string
) => {
  try {
    const response = await api.get(
      `/api/Folder/GetFolderItemsByFolderId?FolderId=${FolderId}`,
      {
        params: {
          AcademyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const GetOwnFolders = async (AcademyId: string) => {
  try {
    const response = await api.get(`/api/Folder/GetOwnFolders`, {
      params: {
        AcademyId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSearchFolders = async (
  AcademyId: string,
  SearchQuery: string
) => {
  try {
    const response = await api.get(
      `/api/Folder/SearchFolders?SearchQuery=${SearchQuery}`,
      {
        params: {
          AcademyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSearchFolderPath = async (
  AcademyId: string,
  FolderId: string
) => {
  try {
    const response = await api.get(
      `/api/Folder/SearchFolderPath?FolderId=${FolderId}`,
      {
        params: {
          AcademyId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createFolder = async (AcademyId: string, Name: string) => {
  try {
    const response = await api.post(`/api/Folder/CreateFolder`, {
      AcademyId,
      Name,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createSubFolder = async (
  AcademyId: string,
  FolderId: string,
  Name: string
) => {
  try {
    const response = await api.post(`/api/Folder/CreateSubFolder`, {
      AcademyId,
      FolderId,
      Name,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addItemToFolder = async (
  AcademyId: string,
  FolderId: string,
  PlayerId: string
) => {
  try {
    const response = await api.post(`/api/Folder/AddItemToFolder`, {
      AcademyId,
      FolderId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateFolderName = async (
  AcademyId: string,
  FolderId: string,
  NewName: string
) => {
  try {
    const response = await api.put(`/api/Folder/UpdateFolderName`, {
      AcademyId,
      FolderId,
      NewName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const grantFolderToTrainer = async (
  AcademyId: string,
  FolderId: string,
  TrainerId: string
) => {
  try {
    const response = await api.post(`/api/Folder/GrantFolderToTrainer`, {
      AcademyId,
      FolderId,
      TrainerId,
      CanCopyOut: true,
      CanTransfer: true,
      CanDeleteFolders: true,
      CanUpdateFolderName: true,
      CanCopyIn: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const revokeFolderToTrainer = async (
  AcademyId: string,
  FolderId: string,
  TrainerId: string
) => {
  try {
    const response = await api.post(`/api/Folder/RevokeFolderToTrainer`, {
      AcademyId,
      FolderId,
      TrainerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const copyItemToFolder = async (
  AcademyId: string,
  FolderFromId: string,
  FolderToId: string,
  CopyItemId: string,
  PlayerId: string
) => {
  try {
    const response = await api.post(`/api/Folder/CopyItemToFolder`, {
      AcademyId,
      FolderFromId,
      FolderToId,
      CopyItemId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const transferItemToFolder = async (
  AcademyId: string,
  FolderFromId: string,
  FolderToId: string,
  TransferItemId: string,
  PlayerId: string
) => {
  try {
    const response = await api.post(`/api/Folder/TransferItemToFolder`, {
      AcademyId,
      FolderFromId,
      FolderToId,
      TransferItemId,
      PlayerId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("W folderze już znajduje się dany obiekt!");
  }
};

export const replaceItem = async (
  AcademyId: string,
  FolderId: string,
  FolderItemId: string,
  OrderTo: number
) => {
  try {
    const response = await api.post(`/api/Folder/ReplaceItem`, {
      AcademyId,
      FolderId,
      FolderItemId,
      OrderTo,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolderItem = async (
  AcademyId: string,
  FolderId: string,
  FolderItemId: string
) => {
  try {
    const response = await api.delete(`/api/Folder/DeleteFolderItem`, {
      data: { FolderId, FolderItemId, AcademyId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolder = async (AcademyId: string, FolderId: string) => {
  try {
    const response = await api.delete(`/api/Folder/DeleteFolder`, {
      data: { FolderId, AcademyId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
