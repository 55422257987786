import { ArtistPropItem } from "types/artistTypes";
import {
  DigitalReleaseRequest,
  IDigitalRelease,
  IAddProduct,
} from "types/digitalReleaseTypes";
import { IListPageRequest, IListPageResponse } from "types/globalTypes";

import { conn } from "../api/BaseConnection";
import jwtDecode from "jwt-decode";

const endpoint = conn.endpoints.event;
const deliveryEndpoint = conn.endpoints.delivery;
const token: any = localStorage?.getItem("token");
const decodedToken: any = token && jwtDecode(token);
console.log("token", decodedToken);
const add = (digitalRelease: IDigitalRelease | any) => {
  return conn.postJSON(endpoint, "json", digitalRelease);
};

const remove = (id: string) => {
  return conn.deleteJSON(`${endpoint}/${id}`);
};

const edit = (digitalRelease: Partial<DigitalReleaseRequest>) => {
  return conn.putJSON(endpoint, "json", digitalRelease);
};

const getAll = (
  pageInfo: IListPageRequest
): Promise<IListPageResponse<IDigitalRelease>> => {
  return conn.getJSON<IListPageResponse<IDigitalRelease>>(
    endpoint,
    "json",
    pageInfo
  );
};

const getAttributes = (): Promise<IDigitalRelease> => {
  return conn.getJSON(`/api/Attributes`, "json");
};

const getCategories = (): Promise<IDigitalRelease> => {
  return conn.getJSON(`/api/Category`, "json");
};

const editCategory = (
  CategoryId: string,
  Name: string,
  Description: string
) => {
  return conn.putJSON(`/api/Category`, "json", {
    CategoryId,
    Name,
    Description,
  });
};

const editLocalization = (EventId: string, City: string, PostCode: string) => {
  return conn.putJSON(`${endpoint}/Localization`, "json", {
    EventId,
    City,
    PostCode,
    Country: 177,
  });
};

const addCategory = (Name: string, Description: string) => {
  return conn.postJSON(`/api/Category`, "json", {
    Name,
    Description,
  });
};

const getArtists = (): Promise<ArtistPropItem[]> => {
  //TODO: remove formatting from this endpoint after backend is fixed
  return conn
    .getJSON<{ items: ArtistPropItem[] }>(endpoint, "json")
    .then((response) => response.items);
};

const getAllDelivery = (pageInfo: any) => {
  return conn.getJSON(`${deliveryEndpoint}`, "json", { ...pageInfo });
};

const getAllEvents = (pageInfo: any) => {
  return conn.getJSON(`/api/Event/Listining`, "json", { ...pageInfo });
};

const getProfile = (ProfileId: any) => {
  return conn.getJSON(`${endpoint}/Profile`, "json", { ProfileId });
};

const getAllSellerProducts = (pageInfo: any) => {
  return conn.getJSON(`${endpoint}/SellerProducts`, "json", {
    ...pageInfo,
    SellerId: decodedToken?.SellerId ? decodedToken?.SellerId : "-",
  });
};

const getEventFields = (activityType: any) => {
  return conn.getJSON(`${endpoint}/Fields`, "json", {
    activityType,
  });
};

const getEvent = (id: string) => {
  return conn.getJSON(`${endpoint}/${id}`, "json");
};

const addProduct = (
  product: IAddProduct | any,
  type: number,
  imageUrl: string,
  categoryId: string
) => {
  delete product.image;
  return conn.postJSON(`${endpoint}/CreateProduct`, "json", {
    ...product,
    Type: type,
    CategoryId: categoryId,
    BannerPhotoFilePath: imageUrl,
    PhotoFilePaths: [],
    SellerId: decodedToken?.SellerId ? decodedToken?.SellerId : "-",
  });
};

const removeProduct = (id: string) => {
  return conn.deleteJSON(`${endpoint}/Product/${id}`);
};

const removeDocument = (DocumentId: any) => {
  return conn.deleteJSON(`${endpoint}/Documents`, DocumentId);
};

const editProduct = (
  product: IAddProduct | any,
  SellerId: string,
  Type: number,
  BannerPhotoFilePath: string
) => {
  const body = {
    ...product,
    SellerId,
    Type,
    BannerPhotoFilePath,
  };

  return conn.putJSON(`${endpoint}/UpdateProduct`, "json", body);
};

const getAllCategories = () => {
  return conn.getJSON(`${endpoint}/Category`, "json");
};

const getCategory = (id: string) => {
  return conn.getJSON(`${endpoint}/Category/${id}`, "json");
};

const getSubCategories = (categoryId: string) => {
  return conn.getJSON(`${endpoint}/SubCategories/${categoryId}`, "json");
};

const updateDescription = (ProductId: string, Description: string) => {
  const body = {
    ProductId,
    Description,
  };

  return conn.putJSON(`${endpoint}/UpdateDescription`, "json", body);
};

const getCategoriesByProductType = (productType: number) => {
  return conn.getJSON(`${endpoint}/GetCategoriesByProductType`, "json", {
    productType,
  });
};

const addProductFiles = (
  ProductId: string,
  imageUrl: string,
  SellerId: string
) => {
  const body = {
    SellerId,
    ProductId,
    PhotoFilePaths: [imageUrl],
  };

  return conn.postJSON(`${endpoint}/AddProductPhotos`, "json", body);
};

const addDocument = (ProductId: string, Name: string, base64: string) => {
  const body = {
    ProductId,
    Name,
    File: {
      Base64String: base64,
    },
  };

  return conn.postJSON(`${endpoint}/Documents`, "json", body);
};

const removeProductFiles = (
  ProductId: string,
  removedId: string,
  ftp: string
) => {
  const body = {
    ProductId,
    RemovedFiles: [{ FileId: removedId, FtpPhotoFilePath: ftp }],
    AddedFiles: [],
  };

  return conn.putJSON(`${endpoint}/UpdateFiles`, "json", body);
};

const getDeliveryByProductType = (productType: number) => {
  return conn.getJSON(`${deliveryEndpoint}/GetAvailableDelivery`, "json", {
    productType,
  });
};

const revokeDelivery = (ProductId: string, DeliveryId: string) => {
  const body = {
    ProductId,
    DeliveryId,
  };

  return conn.postJSON(`${endpoint}/Delivery/Revoke`, "json", body);
};

const deleteCombination = (id: string) => {
  return conn.deleteJSON(`${endpoint}/Combination/${id}`);
};

const grantDelivery = (ProductId: string, DeliveryId: string) => {
  const body = {
    ProductId,
    DeliveryId,
  };

  return conn.postJSON(`${endpoint}/Delivery/Grant`, "json", body);
};

const addFile = (
  AuthorId: string,
  EventId: string,
  FileName: string,
  FilePath: string
) => {
  const body = {
    AuthorId,
    EventId,
    FileName,
    FilePath,
  };

  return conn.postJSON(`${endpoint}/File`, "json", body);
};

const updatePrice = (CombinationId: string, Netto: number, Tax: number) => {
  const body = {
    CombinationId,
    Netto,
    Tax,
  };

  return conn.putJSON(`${endpoint}/UpdatePrice`, "json", body);
};

const getAttributesByCategoryId = (categoryId: string) => {
  return conn.getJSON(`${endpoint}/Attribute/${categoryId}`, "json");
};
const getSurfacesWithDimensions = () => {
  return conn.getJSON(`${endpoint}/SurfacesWithDimensions`, "json");
};

const getRecommendationTypes = () => {
  return conn.getJSON(`${endpoint}/RecommendationTypes/`, "json");
};

const getRecommendations = (ProductId: string) => {
  return conn.getJSON(
    `${endpoint}/Recommendation?ProductId=${ProductId}`,
    "json"
  );
};

const deleteContentAndFiles = (EventId: string) => {
  return conn.deleteJSON(`${endpoint}/ContentAndFiles`, "json", {
    EventId,
  });
};

const editEvent = (
  array: any,
  imageUrl: any,
  OccurenceDate: any,
  EndDate: any,
  Visibility: any,
  Level: any,
  LocalizationType: any,
  Type: any,
  Limit: any,
  allPlayers: any,
  OrganizerType: any,
  Latitude: number,
  Longitude: number,
  IsCalendarChecked: boolean,
  TimeInterval: number,
  IsPostChecked: boolean,
  IsDraft: boolean,
  FilesArray: any,
  GroupIds: any,
  EventId: string
) => {
  delete array.photos;
  delete array.Localization;
  delete array.country;
  delete array.Address;
  delete array.Attendances;

  delete array.City;
  delete array.Invitations;
  delete array.EventId;
  delete array.AttendanceCount;
  delete array.Organizer;
  delete array.Country;
  delete array.OrganizerType;
  delete array.Surface;
  delete array.Status;
  delete array.InvitedGroupsIds;
  const AcademyId = array.Author.Id;
  delete array.Author;
  const LimitAmount = array.Limit.Limit;
  console.log("edycjGroupIds", GroupIds);

  delete array.CountLimit;
  let filesArray: any = [];
  if (IsPostChecked && imageUrl) {
    filesArray = [
      {
        Name: "test",
        FilePath: imageUrl,
        FilePathMin: imageUrl,
      },
    ];
  } else if (IsPostChecked && FilesArray.length > 0) {
    filesArray = [
      {
        Name: FilesArray[0].Name,
        FilePath: FilesArray[0].FilePath,
        FilePathMin: FilesArray[0].FilePathMin,
      },
    ];
  } else {
    filesArray = [];
  }

  const body = {
    ...array,
    AcademyId,
    EventId,
    IsDraft,
    AddToCalendar: IsCalendarChecked,
    TimeInterval,
    LocalizationType,
    Latitude,
    Longitude,
    OrganizerType,
    OccurenceDate,
    EndDate,
    Visibility: Visibility,
    Level,
    Type,
    HasLimit: Limit,
    Limit: Limit ? +LimitAmount : 0,
    Files: IsPostChecked ? filesArray : [],
    DeleteFiles: [],
    Revoke: [],
    Fields: [],
    Invite:
      Visibility === 1
        ? allPlayers.map((player: any) => ({
            Id: player.Id,
            Type: 1,
          }))
        : [],
    Content: IsPostChecked ? array.Content : "",
    GroupIds: Visibility === 2 ? GroupIds : [],
  };

  return conn.putJSON(`${endpoint}`, "json", body);
};

const addEvent = (
  array: any,
  academyId: any,
  imageUrl: any,
  OccurenceDate: any,
  EndDate: any,
  Visibility: any,
  Level: any,
  LocalizationType: any,
  Type: any,
  Limit: any,
  allPlayers: any,
  IsCalendarChecked: boolean,
  TimeInterval: number,
  IsPostChecked: boolean,
  Latitude: number,
  Longitude: number,
  FolderIds: any,
  PostCode: any,
  Localization: any
) => {
  delete array.photos;
  delete array.country;
  let filesArray: any = [];
  if (IsPostChecked) {
    filesArray = [
      {
        Name: "test",
        PhotoFilePath: imageUrl,
        PhotoFilePathMin: imageUrl,
      },
    ];
  }
  const LimitAmount = array.CountLimit;
  delete array.CountLimit;
  delete array.Address;
  delete array.City;
  delete array.Country;

  const body = {
    ...array,
    AcademyId: academyId,
    OccurenceDate,
    AddToCalendar: IsCalendarChecked,
    TimeInterval,
    Latitude,
    LocalizationType,
    Longitude,
    EndDate,
    Visibility: Visibility === 3 ? 1 : Visibility,
    Level,
    Type,
    Files: filesArray,
    HasLimit: Limit,
    Limit: Limit && +LimitAmount ? +LimitAmount : 0,
    Content: array?.Content ? array.Content : "",
    Fields: [],
    InvitedProfiles:
      Visibility === 1
        ? allPlayers.map((player: any) => ({
            Id: player.Id,
            Type: 1,
          }))
        : [],
    GroupIds: Visibility === 2 ? FolderIds : [],
    PostCode: PostCode ? PostCode?.trimStart() : array.PostCode,
    Localization: Localization ? Localization?.trimStart() : array.Localization,
    InviteAllAcademyPlayers: Visibility === 3 ? true : false,
  };

  return conn.postJSON(`${endpoint}`, "json", body);
};

const addRecommendation = (ProductId: string, Recommendation: number) => {
  const body = {
    ProductId,
    Recommendation,
  };

  return conn.postJSON(`${endpoint}/Recommendation`, "json", body);
};

const getCombinations = (productId: string) => {
  return conn.getJSON(`${endpoint}/Combination/${productId}`, "json");
};

const editCombination = (
  SellerId: string,
  ProductId: string,
  CombinationId: string,
  EAN: string,
  Netto: number,
  Tax: number,
  Amount: number
) => {
  const body = {
    SellerId,
    ProductId,
    CombinationId,
    EAN,
    Netto,
    Tax,
    Amount,
  };

  return conn.putJSON(`${endpoint}/UpdateCombination`, "json", body);
};

const getRaportByProductId = (id: string, From: any, To: any) => {
  return conn.getJSON(
    `${endpoint}/Report?ProductId=${id}&From=${From}&To=${To}`,
    "json"
  );
};

const getProductDiscountByCombinationId = (id: string) => {
  return conn.getJSON(`${endpoint}/Discount/GetByCombinationId/${id}`, "json");
};

const addDiscount = (
  SellerId: string,
  CombinationId: string,
  ProductId: string,
  Percent: number
) => {
  const body = {
    SellerId,
    CombinationId,
    ProductId,
    Percent,
  };
  return conn.postJSON(`${endpoint}/ApplyDiscount`, "json", body);
};

const verifyProduct = (SellerId: string, ProductId: number, Status: number) => {
  const body = {
    SellerId,
    ProductId,
    Status,
  };
  return conn.patchJSON(`${endpoint}/VerifyProduct`, "json", body);
};

const editDiscount = (
  DiscountId: string,
  CombinationId: string,
  Netto: number,
  Tax: number,
  Sale: number,
  Started: string,
  Finished: string
) => {
  const body = {
    DiscountId,
    CombinationId,
    Netto,
    Tax,
    Sale,
    Started,
    Finished,
  };
  return conn.putJSON(`${endpoint}/Discount`, "json", body);
};

const editContent = (EventId: string, Content: string) => {
  const body = {
    EventId,
    Content,
  };
  return conn.patchJSON(`${endpoint}/Content`, "json", body);
};

const editNote = (EventId: string, Note: string) => {
  const body = {
    EventId,
    Note,
  };
  return conn.patchJSON(`${endpoint}/Note`, "json", body);
};

const deleteDiscount = (id: string) => {
  return conn.deleteJSON(`${endpoint}/Discount/${id}`);
};

const eventsServices = {
  add,
  edit,
  remove,
  getAll,
  getAttributes,
  getArtists,
  getCategories,
  getAllDelivery,
  getAllEvents,
  deleteContentAndFiles,
  editNote,
  editContent,
  getEvent,
  editEvent,
  addProduct,
  removeProduct,
  editProduct,
  getAllCategories,
  getCategory,
  getSubCategories,
  updateDescription,
  getCategoriesByProductType,
  addProductFiles,
  removeProductFiles,
  getDeliveryByProductType,
  revokeDelivery,
  grantDelivery,
  updatePrice,
  deleteCombination,
  getEventFields,
  getAttributesByCategoryId,
  addEvent,
  getSurfacesWithDimensions,
  editCombination,
  removeDocument,
  addDocument,
  getCombinations,
  getRaportByProductId,
  getProductDiscountByCombinationId,
  addDiscount,
  deleteDiscount,
  getAllSellerProducts,
  addFile,
  getProfile,
  editDiscount,
  getRecommendationTypes,
  getRecommendations,
  addRecommendation,
  addCategory,
  editCategory,
  verifyProduct,
  editLocalization,
};

export default eventsServices;
