import { conn } from "../api/BaseConnection";
import { IListPageRequest, IListPageResponse } from "../types/globalTypes";
import {
  IUserManagement,
  IUserManagementEditData,
  UserInterface,
  UserManagmentEditInterface,
} from "../types/userTypes";

const policyDocumentEndpoint = conn.endpoints.policyDocument;

const getAllAcademyPolicyDocuments = async (
  id: string,
): Promise<IListPageResponse<any>> => {
  return await conn.getJSON<IListPageResponse<any>>(
    `${policyDocumentEndpoint}/GetAcademyPolicyDocuments`,
    "json",
    { AcademyId: id },
  );
};

const createPolicyDocument = (
  AcademyId: string,
  Name: string,
  Description: string,
  FilePath: string,
  IsRequired: boolean,
  PolicyDocumentType: number,
) => {
  const body = {
    AcademyId,
    Name,
    Description,
    FilePath,
    IsRequired,
    PolicyDocumentType,
  };

  return conn.postJSON(
    `${policyDocumentEndpoint}/CreatePolicyDocument`,
    "json",
    body,
  );
};

const updatePolicyDocument = (
  AcademyId: string,
  PolicyDocumentId: string,
  Name: string,
  Description: string,
  FilePath: string,
  IsRequired: boolean,
  PolicyDocumentType: number,
) => {
  const body = {
    AcademyId,
    PolicyDocumentId,
    Name,
    Description,
    FilePath,
    IsRequired,
    PolicyDocumentType,
  };

  return conn.putJSON(
    `${policyDocumentEndpoint}/UpdatePolicyDocumentName`,
    "json",
    body,
  );
};

const deleteDocument = (AcademyId: string, PolicyDocumentId: string) => {
  return conn.deleteJSON(`${policyDocumentEndpoint}/DeleteDocument`, "json", {
    AcademyId,
    PolicyDocumentId,
  });
};

const policyDocumentServices = {
  getAllAcademyPolicyDocuments,
  createPolicyDocument,
  updatePolicyDocument,
  deleteDocument,
};

export default policyDocumentServices;
