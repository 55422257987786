import { conn } from "api/BaseConnection";
import api from "./axiosConfig";
import {
  ACADEMY_ACCESS_STATUS,
  ACADEMY_VISIBILITY,
  IAcademy,
  IUpdateAcademyPrimaryData,
  IUpdateAcademyUnion,
} from "types/academy.interface";

const endpoint = conn.endpoints.adacemy;

export const getAllAcademyUsers = async () => {
  try {
    const response = await api.get("/api/Academy/Users");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAcademyBranch = async (AcademyId: string) => {
  try {
    const response = await api.delete(endpoint.branch.delete, {
      data: { AcademyId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAcademyUsersRef = (pageInfo: any) => {
  return conn.getJSON("/api/Academy/Users", "json", { ...pageInfo });
};

export const getAllAcademyUsersContact = async () => {
  try {
    const response = await api.get("/api/Academy/UsersContact");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAcademy = async (AcademyId: string): Promise<IAcademy> => {
  return await conn.getJSON<IAcademy>(endpoint.get, "json", {
    AcademyId,
  });
};

export const getBasicAcademy = async (): Promise<any> => {
  return await conn.getJSON<any>(endpoint.basicAcademy, "json");
};

export const getAcademyDetailData = async (): Promise<any> => {
  return await conn.getJSON<any>(endpoint.detailAcademyData, "json");
};

export const changeAcademyVisibility = async (
  AcademyId: string,
  visibility: ACADEMY_VISIBILITY
) => {
  return await conn.putJSON(endpoint.changeVisibility, "json", {
    AcademyId,
    NewAcademyVisibility: visibility,
  });
};

export const updateAcademyVisibilityRadius = async (
  AcademyId: string,
  VisibilityRadiusInKilometers: number
) => {
  return await conn.putJSON(endpoint.update.visibilityRadius, "json", {
    AcademyId,
    VisibilityRadiusInKilometers,
  });
};

export const updateAcademyPrimaryData = async (
  AcademyId: string,
  PrimaryData: IUpdateAcademyPrimaryData
) => {
  return await conn.putJSON(endpoint.update.primaryData, "json", {
    ...PrimaryData,
    AcademyId,
  });
};

export const createAcademyBranch = async (
  AcademyId: string,
  Name: string,
  ShortName: string,
  LogoFilePath: string,
  WebsiteUrl: string
) => {
  const body = {
    AcademyId,
    Name,
    ShortName,
    LogoFilePath,
    Description: "",
    WebsiteUrl,
    AccessStatus: 0,
    Visibility: 0,
    IsInUnion: false,
    Union: null,
  };
  return await conn.postJSON(endpoint.branch.create, "json", body);
};

export const updateAcademyDescription = async (
  AcademyId: string,
  PrimaryData: string
) => {
  return await conn.putJSON(endpoint.update.description, "json", {
    AcademyId,
    Description: PrimaryData,
  });
};

export const updateAcademyUnion = async (Union: IUpdateAcademyUnion) => {
  return await conn.putJSON(endpoint.update.union, "json", Union);
};

export const updateAcademyAccessState = async (
  AcademyId: string,
  AccessState: ACADEMY_ACCESS_STATUS
) => {
  return await conn.putJSON(endpoint.update.accessState, "json", {
    AcademyId,
    NewTransferAcceptanceStatus: AccessState,
  });
};
