import { LegacyRef } from "react";
import { Country } from "../utils/constants/countires";

export interface ITableProps<Data, Sort> {
  data: Data[];
  containerRef: LegacyRef<HTMLTableSectionElement>;
  lastItemRef: LegacyRef<HTMLTableSectionElement>;
  isDataLoading: boolean;
  sortBy: ISelectOption<Sort> | null;
  detailPassPermission?: boolean;
}

export interface IStrictDetailTab<STRICT_LEVEL> {
  id: string;
  name: string;
  strictLevel: STRICT_LEVEL;
}

export interface ITabsContext<IData> {
  data: IData;
  refresh: () => Promise<void>;
}

export interface ITab {
  label: string;
  id: string;
  disabled?: boolean;
  permission?: boolean;
  content?: React.ReactElement;
}

export interface ISelectOption<ValueType = any> {
  label: string;
  value: ValueType;
  detailPassPermission?: string;
}

export interface IListPageRequest {
  pageNumber: number;
  pageSize?: number;
  searchString?: string;
}

export interface IPassTransactionSummery {
  FromDate: string;
  ToDate: string;
}

export interface IListPageMeta extends IListPageRequest {
  HasNextPage: boolean;
  HasPreviousPage: boolean;
}

export interface IListPageResponse<T> extends IListPageMeta {
  Items: Array<T>;
}

export interface IRequestSucces<T = any> {
  data: T;
}

export interface ICheckboxValue<T = any> {
  checked: boolean;
  value: T;
}

export interface IFileFormat {
  pdf: Array<string>;
  image: Array<string>;
}

export interface ImageFile {
  base64: string;
  file: File;
  path: string;
  sizes: {
    height: number;
    width: number;
    ratio: number;
  };
}

export enum DataViewMode {
  table,
  tiles,
}

export enum DataViewType {
  artists = "artists",
  contacts = "contacts",
  digitalRelease = "digitalRelease",
  licensors = "licensors",
  providers = "providers",
  tracks = "tracks",
  usersManagment = "usersManagment",
}

export enum LIST_SORT {
  "Najnowsze",
  "Najstarsze",
  "Nazwa (A-Z)",
  "Nazwa (Z-A)",
}

export enum LIST_FILTER {
  "Relacja",
  "Status",
  "Grupy",
  "Składki",
}

export interface IFilter {
  name: string;
  values?: any;
  pl?: any;
  id?: any;
  type?: string;
}

export interface IAddress {
  postCode: string;
  street: string;
  city: string;
  country: Country;
}

export const AddressLabels: Record<keyof IAddress, string> = {
  postCode: "Kod pocztowy",
  street: "Ulica",
  city: "Miasto",
  country: "Kraj",
};

export interface IFile {
  file: File;
  base64String: string;
  path: string;
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type SortState = {
  key: string;
  direction: SortDirection;
};
