import styled from "styled-components";
import Folder from "assets/icons/structure-folder.png";
import { useRef, useState } from "react";

interface Props {
  name: string;
  id: string;
  style?: React.CSSProperties;
  selectedFolderId: string;
  setSelectedFolderId: any;
  isSelected: boolean;
}

const SingleAcademy: React.FC<Props> = ({
  name,
  id,
  style,
  selectedFolderId,
  setSelectedFolderId,

  isSelected,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleContainerClick = () => {
    containerRef.current?.focus();
    setSelectedFolderId(id);
  };

  const handleFolderMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container
      style={style}
      onClick={handleContainerClick}
      selectedFolderId={selectedFolderId}
      id={id}
      onMouseLeave={handleFolderMouseLeave}
      tabIndex={0}
      isSelected={isSelected}
    >
      <div>
        <div>
          <p>{name ? name : "-"}</p>

          <div>
            <div></div>
            <div>{/* <p>ZAWODNIKÓW</p> */}</div>
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10.189"
          height="21"
          viewBox="0 0 10.189 21"
        >
          <path
            id="Path_21495"
            data-name="Path 21495"
            d="M449.656,1344.208a1.391,1.391,0,0,0,1.079-.513l7.411-9.107a1.393,1.393,0,0,0,0-1.755l-7.411-9.114a1.389,1.389,0,0,0-2.155,1.754l6.7,8.237-6.7,8.231a1.389,1.389,0,0,0,1.076,2.266Z"
            transform="translate(-448.268 -1323.208)"
            // fill="rgba(248,248,248,0.5)"
          />
        </svg>
      </div>
      <FolderSvg
        selectedFolderId={selectedFolderId}
        id={id}
        // width="340"
        // height="208"
        // viewBox="0 0 184.333 169"
        isHovered={isHovered}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="256"
          height="125.12"
          viewBox="0 0 256 125.12"
        >
          <defs>
            <filter
              id="Path_21500"
              x="0"
              y="0"
              width="256"
              height="125.12"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="2.5" result="blur" />
              <feFlood />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_21500)">
            <path
              id="Path_21500-2"
              data-name="Path 21500"
              d="M232.948,14.65,47.479,14.482a1.466,1.466,0,0,1-1.312-.821L40.059,1.552A2.825,2.825,0,0,0,37.538,0H7.059A7.059,7.059,0,0,0,0,7.059v94.5a7.059,7.059,0,0,0,7.059,7.059H232.942A7.059,7.059,0,0,0,240,101.561V21.71a7.059,7.059,0,0,0-7.052-7.059"
              transform="translate(8 0.5)"
              fill="#1C2A37"
              stroke="rgba(156,176,196,0.3)"
              stroke-width="1"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </FolderSvg>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="3.563"
        viewBox="0 0 18 3.563"
      >
        <g
          id="Group_17670"
          data-name="Group 17670"
          transform="translate(-321 -48)"
        >
          <path
            id="Path_5620"
            data-name="Path 5620"
            d="M17.318,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
            transform="translate(312.673 32.672)"
            fill="#9cb0c4"
          />
          <path
            id="Path_5621"
            data-name="Path 5621"
            d="M6.5,15.328a1.9,1.9,0,0,0-2,1.781,1.9,1.9,0,0,0,2,1.781,1.9,1.9,0,0,0,2-1.781,1.9,1.9,0,0,0-2-1.781Z"
            transform="translate(316.5 32.672)"
            fill="#9cb0c4"
          />
          <path
            id="Path_5622"
            data-name="Path 5622"
            d="M28.16,15.328a1.9,1.9,0,0,0-2,1.781,2.018,2.018,0,0,0,4.008,0,1.9,1.9,0,0,0-2-1.781Z"
            transform="translate(308.836 32.672)"
            fill="#9cb0c4"
          />
        </g>
      </StyledSvg>
    </Container>
  );
};

export default SingleAcademy;

const FolderSvg = styled.svg<{
  isHovered: boolean;
  selectedFolderId: string;
  id: string;
}>`
  position: absolute;
  top: -4px;
  left: -8px;
  /* left: ${(props) =>
    props.selectedFolderId === props.id ? "-8px" : "-5px"}; */
  /* left: ${(props) =>
    props.selectedFolderId === props.id
      ? "-8px"
      : props.isHovered
      ? "-8px"
      : "-5px"}; */
  z-index: 2;
  fill: #1d2a36;
  stroke: ${(props) =>
    props.selectedFolderId === props.id
      ? "rgba(0,145,255,1)"
      : props.isHovered
      ? "rgba(0,145,255,1)"
      : "rgba(156, 176, 196, 0.3)"};
  padding-left: 20px;
  & > svg {
    & > g {
      & > path {
        width: 220px !important;
        stroke: ${(props) =>
          props.selectedFolderId === props.id
            ? "rgba(0,145,255,1)"
            : props.isHovered
            ? "rgba(0,145,255,1)"
            : "rgba(156, 176, 196, 0.3)"};
      }
    }
  }
  /* width: 100%;
  height: 100%; */
`;

const Container = styled.div<{
  selectedFolderId: any;
  id: any;
  isSelected: boolean;
}>`
  cursor: pointer;
  width: 100%;
  height: 108px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  a:focus &:focus {
    outline: none;
  }
  * > :focus {
    outline: none;
  }

  & > div:first-of-type {
    height: 97px;
    /* margin-left: 20px; */
    /* width: 100%; */
    /* width: 100%; */
    transition: width 0.1s ease-in-out;
    width: ${(props) => (props.isSelected ? "260px" : "220px")};

    background: ${(props) => (props.isSelected ? "#0091FF" : "#0091FF")};

    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    /* border-radius: 16px; */
    border-radius: 7px;
    margin-left: 12px;

    & > div {
      height: 70%;

      min-width: ${(props) => (props.isSelected ? "230px" : "230px")};
      display: flex;
      margin-right: 10px;
      flex-direction: column;
      gap: 8px;
      z-index: 3;
      border-top: 1px solid black;

      display: flex;
      justify-content: center;
      align-items: center;
      /* padding-right: 30px; */

      & > p:nth-of-type(1) {
        font-size: 15px;
        color: white;
        line-height: 15px;
        height: 50px;
        padding-top: 15px;
      }

      & > div {
        position: relative;
        font-size: 11px;
        color: white;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        & > div:first-of-type {
          width: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 8px;
        }

        & > div:last-of-type {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & > p {
            color: rgb(156, 176, 196);
            font-size: 11px;
          }
        }
      }
    }

    & > svg {
      position: absolute;
      /* right: 10px; */
      fill: ${(props) =>
        props.isSelected ? "rgba(248,248,248,0.5)" : "rgba(248,248,248,0.5)"};
      right: ${(props) => (props.isSelected ? "10px" : "50px")};
      stroke: none;
      transition: all 0.1s ease-in-out;
    }
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  width: 20px;
  height: 15px;
  top: -10px;
  right: 36px;
  cursor: pointer;
  z-index: 99;
`;
