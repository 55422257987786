import { ACADEMY_VISIBILITY, IAcademy } from "types/academy.interface";
import Switch from "react-switch";
import { useState } from "react";
import { showServerErrors } from "utils/errorsUtils";
import {
  changeAcademyVisibility,
  deleteAcademyBranch,
} from "services/academyServices";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

interface IAcademyTopbarProps {
  academy: IAcademy;
  refresh: () => Promise<void>;
  isAdmin: boolean;
}

const AcademyTopbar = ({ academy, refresh, isAdmin }: IAcademyTopbarProps) => {
  const [visibility, setVisibility] = useState(academy.Visibility);
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isRemoveing, setIsRemoveing] = useState(false);

  const handleRemove = async () => {
    try {
      setIsRemoveing(true);
      await deleteAcademyBranch(id);

      push("/academy");
      toast.success("Usunięto oddział akademii!");
      setIsRemoveing(false);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <div className="w-full h-full px-24 flex justify-between">
      <div
        style={{
          display: "flex",
          width: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="" alt="" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ height: '48px' }}
      >
        {!academy.IsMainBranch && isAdmin && (
          <Button
            variant={ButtonVariant.Delete}
            className="flex"
            onClick={handleRemove}
            disabled={isRemoveing}
          >
            Usuń
          </Button>
        )}
      </div>
      {/* <div className="w-full" style={{ height: "48px" }}>
        {" "}
      </div> */}
    </div>
  );
};

export default AcademyTopbar;
