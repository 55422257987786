import TabContent from "components/common/tabs/TabContent";
import React, { useEffect, useState } from "react";
import passService from "services/transfer/passServices";
import { IPass } from "types/transfer/pass.interface";
import { showServerErrors } from "utils/errorsUtils";
import FormTableHeader from "components/common/FormTable/FormTableHeader";
import { PassAppliedProfiles } from "./appliedProfiles/PassAppliedProfiles";
import AcademyDocumentsTable from "./table/AcademyDocumentsTable";
import { useParams } from "react-router-dom";
import policyDocumentServices from "services/policyDocumentServices";

interface Props {
  isDocumentEditPermissionActive?: boolean;
  isDocumentAddPermissionActive?: boolean;
  isDocumentListActive?: boolean;
}

const AcademyDocuments: React.FC<Props> = ({
  isDocumentEditPermissionActive,
  isDocumentAddPermissionActive,
  isDocumentListActive,
}) => {
  const [isAddFormDisplayed, displayAddForm] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [previewedDocument, setPreviewedDocument] = useState<any | null>(null);
  const { id } = useParams<{ id: string }>();
  const [documents, setDocuments] = useState<any[]>([]);

  const getDocuments = async () => {
    try {
      const { Items } =
        await policyDocumentServices.getAllAcademyPolicyDocuments(id);

      if (!Items.length) {
        setEditMode(false);
        displayAddForm(true);
      }

      setDocuments(Items);
    } catch (error: any) {
      console.log(error);
      // showServerErrors(error);
    }
  };

  useEffect(() => {
    if (isDocumentListActive) {
      getDocuments();
    }
  }, [isDocumentListActive]);

  return (
    <TabContent id="documents">
      <div className="flex flex-col w-full gap-1">
        <FormTableHeader
          header="Dokumenty"
          isAddFormDisplayed={isAddFormDisplayed}
          displayAddForm={displayAddForm}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          editDisabled={!documents.length}
          isPassEditPermissionActive={isDocumentEditPermissionActive}
          isPassAddPermissionActive={isDocumentAddPermissionActive}
        />
        <AcademyDocumentsTable
          isAddFormDisplayed={isAddFormDisplayed}
          displayAddForm={displayAddForm}
          isEditMode={isEditMode}
          documents={documents}
          setDocument={setPreviewedDocument}
          refreshDocuments={getDocuments}
        />
      </div>
    </TabContent>
  );
};

export default AcademyDocuments;
