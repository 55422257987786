import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as FileIco } from "assets/icons/filesIco.svg";
import passService from "services/transfer/passServices";
import {
  GridItem,
  GridTextItem,
  StyledTableCurrencyInput,
  StyledTableSelect,
  StyledInput,
} from "styles/styles";
import { showServerErrors } from "utils/errorsUtils";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/add-circle-white.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/icons/save.svg";
import fileIco from "assets/icons/academy-document-file.png";
import { getGrossPrice } from "utils/baseUtils";
import { getFormatedDate } from "utils/dateUtils";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";
import EditButton from "components/common/buttons/basicButton/EditButton";
import SaveButton from "components/common/buttons/basicButton/SaveButton";
import CancelButton from "components/common/buttons/basicButton/CancelButton";
import policyDocumentServices from "services/policyDocumentServices";
import { useParams } from "react-router-dom";
import systemServices from "services/systemServices";
import styled from "styled-components";

interface IAcademyPassesRowProps {
  isEditMode: boolean;
  document: any;
  setUpdatedDocument?: any;
  updatedDocument?: any;
  setDocument?: any;
  refreshDocuments: () => Promise<void>;
}

const AcademyDocumentsRow = ({
  isEditMode,
  document,
  refreshDocuments,
  setUpdatedDocument,
  updatedDocument,
  setDocument,
}: IAcademyPassesRowProps) => {
  const { id } = useParams<{ id: string }>();
  const [fileName, setFileName] = useState<string | null>(null);
  useEffect(() => {
    if (!isEditMode) {
      setUpdatedDocument(undefined);
    }
  }, [isEditMode]);
  const [addedFileBase64, setAddedFileBase64] = useState("");
  const updateDocument = async (newDocument: any) => {
    try {
      const {
        AcademyId,
        Description,
        IsRequired,
        Name,
        PolicyDocumentFilePath,
        PolicyDocumentType,
        PolicyDocumentId,
      } = newDocument;
      await policyDocumentServices.updatePolicyDocument(
        AcademyId,
        PolicyDocumentId,
        Name,
        Description,
        PolicyDocumentFilePath,
        IsRequired,
        PolicyDocumentType
      );
      toast.success(`Pomyślnie zapisano dokument ${newDocument.Name}`);

      setUpdatedDocument(undefined);

      await refreshDocuments();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const documentTypeSwitch = (PolicyDocumentType: number) => {
    switch (PolicyDocumentType) {
      case 1:
        return "Rejestracja";

      default:
        return PolicyDocumentType;
    }
  };

  const deleteDocument = async (document: any) => {
    try {
      await policyDocumentServices.deleteDocument(
        id,
        document.PolicyDocumentId
      );

      toast.success(`Pomyślnie usunięto dokument ${document.Name}`);

      setUpdatedDocument(undefined);

      await refreshDocuments();
    } catch (error: any) {
      showServerErrors(error);
    }
  };
  const uploadDocument = async (base64: any) => {
    const fileUrl = await systemServices.uploadFile(base64);

    setUpdatedDocument((prev: any) => ({
      ...prev,
      PolicyDocumentFilePath: fileUrl,
    }));
  };

  useEffect(() => {
    if (addedFileBase64) {
      uploadDocument(addedFileBase64);
    }
  }, [addedFileBase64]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1];
          setAddedFileBase64(base64String);
        }
      };
    }
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "10px",
    width: "20px",
    height: "20px",
  };

  const StyledLabel = styled.label`
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background: transparent;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  `;

  return (
    <>
      {!!updatedDocument ? (
        <GridTextItem>
          <StyledInput
            type="string"
            onChange={(e: any) =>
              setUpdatedDocument({
                ...updatedDocument,
                Name: e.target.value,
              })
            }
            value={updatedDocument.Name}
            placeholder={document.Name}
          />
        </GridTextItem>
      ) : (
        <GridTextItem>{document?.Name ?? "-"}</GridTextItem>
      )}

      {!!updatedDocument ? (
        <GridTextItem>
          <StyledInput
            type="string"
            onChange={(e: any) =>
              setUpdatedDocument({
                ...updatedDocument,
                Description: e.target.value,
              })
            }
            value={updatedDocument.Description}
            placeholder={document.Description}
          />
        </GridTextItem>
      ) : (
        <GridTextItem>{document?.Description ?? "-"}</GridTextItem>
      )}

      {!!updatedDocument ? (
        <GridItem>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <StyledLabel htmlFor="fileInput">
              <img src={fileIco} style={iconStyle} />
              {fileName ? fileName : "Wybierz plik"}
            </StyledLabel>
            {/* <input type="file" onChange={handleFileChange} /> */}
          </div>
        </GridItem>
      ) : (
        <GridTextItem>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <a
              href={document.PolicyDocumentFilePath}
              target="_blank"
              rel="noreferrer"
              download={document.Name}
              className="opacity-70 transition-colors duration-150 hover:text-green"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SaveButton />
            </a>
          </div>
        </GridTextItem>
      )}

      {!!updatedDocument ? (
        <GridItem>
          <input
            type="checkbox"
            style={{ width: "20px", height: "20px" }}
            checked={updatedDocument.IsRequired}
            onChange={(e) =>
              setUpdatedDocument((prev: any) => ({
                ...prev,
                IsRequired: !updatedDocument.IsRequired,
              }))
            }
          />
        </GridItem>
      ) : (
        <GridTextItem>{document?.IsRequired ? "tak" : "nie"}</GridTextItem>
      )}

      {!!updatedDocument ? (
        <GridItem>
          <StyledTableSelect value={updatedDocument?.PolicyDocumentType}>
            <option key={1} value={1}>
              Rejestracja
            </option>
          </StyledTableSelect>
        </GridItem>
      ) : (
        <GridTextItem>
          {documentTypeSwitch(document?.PolicyDocumentType)}
        </GridTextItem>
      )}

      <GridItem>
        {isEditMode &&
          (!updatedDocument ? (
            <div
              className="flex w-full h-full gap-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <EditButton
                onClick={() => {
                  setUpdatedDocument(document);
                }}
              />

              <DeleteButton onClick={() => deleteDocument(document)} />
            </div>
          ) : (
            <div
              className="flex w-full h-full gap-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <SaveButton
                onClick={() => updateDocument(updatedDocument)}
                disabled={document === updatedDocument}
                variant={ButtonVariant.Submit}
                className="w-2/3 p-0 h-10 "
              />

              <CancelButton
                onClick={() => {
                  setUpdatedDocument(undefined);
                }}
                variant={ButtonVariant.Abort}
                className="w-1/3 p-0 h-10 "
              />
            </div>
          ))}
      </GridItem>
    </>
  );
};

export default AcademyDocumentsRow;
