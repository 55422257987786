import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  GridItem,
  StyledTableCurrencyInput,
  StyledTableInput,
  StyledTableSelect,
} from "styles/styles";
import { enumToSelectOptions, getGrossPrice } from "utils/baseUtils";
import {
  IPassForm,
  PASS_PAYMENT_TYPE,
  PASS_PRICE_TYPE,
  PASS_TYPE,
} from "types/transfer/pass.interface";
import {
  getFreePassFromPassForm,
  getPaidPassFromPassForm,
  getPassFormInitialValues,
} from "middleware/pass/getFreePassFromPaid";
import { useDateInputRange } from "hooks/useDateInputRange";
import passService from "services/transfer/passServices";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import { paymentTypeDisabled, setPassType, setPriceType } from "../utils";
import { useParams } from "react-router-dom";
import SaveButton from "components/common/buttons/basicButton/SaveButton";
import AddButton from "components/common/buttons/basicButton/AddButton";
import systemServices from "services/systemServices";
import ProductImageInput from "components/common/inputs/imageInput/ProductImageInput";
import policyDocumentServices from "services/policyDocumentServices";
import styled from "styled-components";
import fileIco from "assets/icons/academy-document-file.png";

const AcademyDocumentsAddForm = ({ displayAddForm, refreshDocuments }: any) => {
  const { id } = useParams<{ id: string }>();
  const [fileName, setFileName] = useState<string | null>(null);
  const getDocumentFormInitialValues = {
    AcademyId: id,
    Name: "",
    Description: "",
    FilePath: "/",
    IsRequired: false,
    PolicyDocumentType: 1,
  };

  const [document, setDocument] = useState<any>(getDocumentFormInitialValues);
  const [addedFileBase64, setAddedFileBase64] = useState("");

  const iconStyle: React.CSSProperties = {
    marginRight: "10px",
    width: "20px",
    height: "20px",
  };

  const StyledLabel = styled.label`
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background: transparent;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  `;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1];
          setAddedFileBase64(base64String);
        }
      };
    }
  };

  useEffect(() => {
    if (addedFileBase64) {
      uploadDocument(addedFileBase64);
    }
  }, [addedFileBase64]);

  const uploadDocument = async (base64: any) => {
    const fileUrl = await systemServices.uploadFile(base64);

    setDocument((prev: any) => ({ ...prev, FilePath: fileUrl }));
  };

  const submitForm = async () => {
    try {
      if (!document.Name.length) {
        return toast.error("Wpisz nazwę dokumentu");
      }
      if (!document.Description.length) {
        return toast.error("Wpisz opis dokumentu");
      }
      // if (!document.FilePath.length) {
      //   return toast.error("Prześlij dokument");
      // }
      const {
        AcademyId,
        Name,
        Description,
        FilePath,
        IsRequired,
        PolicyDocumentType,
      } = document;
      await policyDocumentServices.createPolicyDocument(
        AcademyId,
        Name,
        Description,
        FilePath,
        IsRequired,
        PolicyDocumentType
      );

      displayAddForm(false);
      await refreshDocuments();
      console.log("Created document:", document);
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <>
      <GridItem>
        <StyledTableInput
          type="text"
          value={document.Name}
          onChange={(e) =>
            setDocument((prev: any) => ({ ...prev, Name: e.target.value }))
          }
        />
      </GridItem>
      <GridItem>
        <StyledTableInput
          type="text"
          value={document.Description}
          onChange={(e) =>
            setDocument((prev: any) => ({
              ...prev,
              Description: e.target.value,
            }))
          }
        />
      </GridItem>

      <GridItem>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {/* <input type="file" onChange={handleFileChange} /> */}
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <StyledLabel htmlFor="fileInput">
            <img src={fileIco} style={iconStyle} />
            {fileName ? fileName : "Wybierz plik"}
          </StyledLabel>
        </div>
      </GridItem>
      <GridItem>
        <input
          type="checkbox"
          style={{ width: "20px", height: "20px" }}
          checked={document.IsRequired}
          onChange={(e) =>
            setDocument((prev: any) => ({
              ...prev,
              IsRequired: !prev.IsRequired,
            }))
          }
        />
      </GridItem>

      <GridItem>
        <StyledTableSelect value={document.PolicyDocumentType}>
          <option key={1} value={1}>
            Rejestracja
          </option>
        </StyledTableSelect>
      </GridItem>

      <GridItem>
        <AddButton
          onClick={submitForm}
          variant={ButtonVariant.Add}
          // disabled={!name.length || !type.length || (!isFree && netto < 0)}
          className="w-full h-full p-0"
        />
      </GridItem>
    </>
  );
};

export default AcademyDocumentsAddForm;
