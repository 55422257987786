import { conn } from "api/BaseConnection";
import { IAcademyMembersCount } from "types/academy.interface";
import { IListPageRequest, IListPageResponse } from "types/globalTypes";
import { TRANSFER_LEVEL } from "types/transfer/player.interface";
import { ISentTransfer } from "types/transfer/transfer.interface";

const endpoints = conn.endpoints.transfer;

const getAcademyMembersCount = async (
  id: string,
): Promise<IAcademyMembersCount> => {
  return await conn.getJSON<IAcademyMembersCount>(
    endpoints.getAcademyMembers,
    "json",
    { AcademyId: id },
  );
};

const send = async (
  PlayerId: string,
  Level: TRANSFER_LEVEL,
): Promise<string> => {
  return await conn.postJSON<string>(endpoints.send, "json", {
    PlayerId,
    Level,
  });
};

const accept = async (
  TransferRequestId: string,
  PlayerId: string,
): Promise<void> => {
  return await conn.postJSON(endpoints.accept, "json", {
    PlayerId,
    TransferRequestId,
  });
};

const exclude = async (PlayerId: string): Promise<void> => {
  return await conn.patchJSON(endpoints.exclude, "json", { PlayerId });
};

const include = async (PlayerId: string): Promise<void> => {
  return await conn.patchJSON(endpoints.include, "json", { PlayerId });
};

const getAll = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<ISentTransfer>>(
    endpoints.get,
    "json",
    { ...listPage },
  );
};

const revoke = async (PlayerId: string): Promise<void> => {
  await conn.deleteJSON(endpoints.revoke, "json", { PlayerId });
};

const cancel = async (RequestId: string): Promise<void> => {
  await conn.deleteJSON(endpoints.cancel, "json", { RequestId });
};

const designateTrainerForPlayer = async (
  TrainerId: string,
  PlayerId: string,
): Promise<{}> => {
  return await conn.postJSON(endpoints.trainerForPlayer.designate, "json", {
    TrainerId,
    PlayerId,
  });
};

const removeTrainerForPlayer = async (TrainerId: string, PlayerId: string) => {
  return await conn.deleteJSON(endpoints.trainerForPlayer.remove, "json", {
    TrainerId,
    PlayerId,
  });
};

export const getDesignatePlayers = (trainerId: any) => {
  return conn.getJSON(endpoints.trainerForPlayers.getDesignate, "json", {
    trainerId,
  });
};

const transferService = {
  getAcademyMembersCount,
  send,
  accept,
  exclude,
  include,
  getAll,
  revoke,
  cancel,
  designateTrainerForPlayer,
  removeTrainerForPlayer,
  getDesignatePlayers,
};

export default transferService;
